import { css } from "styled-components";
// import font fa
import "./fonts.css";

export const fontLoader = css`
  @font-face {
    font-family: "Knockout-HTF49-Liteweight";
    src: url("../fonts/Knockout-HTF49-Liteweight.eot");
    src: url("../fonts/Knockout-HTF49-Liteweight.eot?#iefix")
        format("embedded-opentype"),
      url("../fonts/Knockout-HTF49-Liteweight.woff2") format("woff2"),
      url("../fonts/Knockout-HTF49-Liteweight.woff") format("woff"),
      url("../fonts/Knockout-HTF49-Liteweight.svg#Knockout-HTF49-Liteweight")
        format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
`;

// ugly font fallback stack
export const fontStack = `"Knockout-HTF49-Liteweight", "Knockout", "HelveticaNeue-Light",
    "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande",
    sans-serif
`;

// ugly font fallback stack
export const fontFamily = css`
  font-family: ${fontStack};
`;
// common font settings
export const fontBasics = css`
  ${fontFamily}
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 7.5px;
  line-height: 0.94;
  -webkit-font-smoothing: antialiased;
  font-smooth: always;
`;
