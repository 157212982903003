import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import CenteredCol from "./centered-col";
import Row from "./row";
import Col from "./col";
import NavigationMobile from "./navigation-mobile";
import { trackClick } from "~src/utils/track";

const Navigation = ({ menuItems, rootPath = "", fixed = true, Logo }) => {
  const handleLogoClick = () =>
    trackClick({ event_category: "navigation", event_label: "header-logo" });

  return (
    <StyledNav role='navigation' aria-label='main-navigation' fixed={fixed}>
      <CenteredCol>
        <Row align='center' justify='end'>
          <Col xs={3} sm={5} md={6} lg={6}>
            {Logo && (
              <Link to={rootPath} onClick={handleLogoClick}>
                <Logo />
              </Link>
            )}
          </Col>
          <Col xs={1} sm={3} md={2} lg={6}>
            {menuItems && menuItems.length > 0 && (
              <NavigationMobile menuItems={menuItems} />
            )}
          </Col>
        </Row>
      </CenteredCol>
    </StyledNav>
  );
};

const StyledNav = styled.nav``;

export default Navigation;
