import React from "react";
import styled from "styled-components";

function BurgerMenu({
  title = "Burger Menu",
  titleId = "burger-menu-svg",
  ...props
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={43.682}
      height={27.866}
      viewBox='0 0 43.682 27.866'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <g data-name='Group 7' fill='currentColor'>
        <path data-name='Rectangle 7' d='M0 27.866v-4.519h43.682v4.519z' />
        <path data-name='Rectangle 8' d='M0 15.816v-4.519h43.682v4.519z' />
        <path data-name='Rectangle 9' d='M0 4.519V0h43.682v4.519z' />
      </g>
    </svg>
  );
}

const StyledBurgerMenu = styled(BurgerMenu)`
  color: ${({ $color = "white", theme }) =>
    theme.colors[$color] ? theme.colors[$color] : $color};
`;

export default StyledBurgerMenu;
