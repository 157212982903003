export const allGridProps = [
  "xs",
  "sm",
  "md",
  "lg",
  "xl",
  "offset",
  "align",
  "justify",
  "reverse",
  "order",
  "fluid",
  "noGutter",
  "debug",
];

export const gridPropsNotToPass = {
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !allGridProps.includes(prop) && defaultValidatorFn(prop),
};
