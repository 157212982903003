import React from "react";
import styled from "styled-components";
import Container from "~components/ui/container";
import Col from "~components/ui/col";
import Row from "~components/ui/row";

const CenteredCol = ({ children, justify, align, ...props }) => {
  return (
    <StyledContainer {...props}>
      <Row justify={justify} align={align}>
        <Col
          xs={4}
          sm={8}
          md={6}
          lg={8}
          xlg={8}
          offset={{ xs: 0, sm: 0, md: 1, lg: 2, xl: 2 }}
        >
          {children}
        </Col>
      </Row>
    </StyledContainer>
  );
};

const StyledContainer = styled(Container)``;

export default CenteredCol;
