const baseColors = {
  darkPurple: "#663399",
  lightPurple: "#ccb3e6",
  white: "#ffffff",
  black: "#000000",
  lightGray: "#707070",
  gray: "#1A1A1A",
  red: "#EB1651",
};

const united = {
  ...baseColors,
  red: "#EB1651",
  kit: "#7FA6D5", // used for a few elements at the top of the screen in the United layout
};

const falcons = {
  ...baseColors,
  red: "#A30D2D",
};
falcons.kit = falcons.red; // a different color is not used in the Falcons layout, keep this the same red

const colors = {
  falcons,
  united,
  ...baseColors,
};

export default colors;
