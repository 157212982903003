import { zipObj } from "rambdax";

export const breakpoints = [400, 576, 768, 992, 1200, 1600];
export const screenClasses = ["xxs", "xs", "sm", "md", "lg", "xl", "xxl"];
export const sizes = zipObj(screenClasses, [0, ...breakpoints]);

const flexbox = {
  breakpoints: breakpoints,
  containerWidths: [372, 540, 740, 960, 1140, 1540],
  gutterWidth: 60,
  gridColumns: 12,
  defaultScreenClass: "xxl",
  maxScreenClass: "xxl",
};

export default flexbox;
