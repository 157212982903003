/**
 * Layout component that uses the awesome-styled-grid Container
 * See: https://awesome-styled-grid.netlify.app/usage
 */
import styled from "styled-components";
import { Container as GridContainer } from "react-awesome-styled-grid";
import { gridPropsNotToPass } from "~styles/mixins/forward-props";

const Container = styled(GridContainer).withConfig(gridPropsNotToPass)``;

export default Container;
