import React from "react";
import styled from "styled-components";

function MenuClose({
  title = "Menu Close",
  titleId = "menu-close-svg",
  ...props
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={40.177}
      height={42.356}
      viewBox='0 0 40.177 42.356'
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        data-name='Rectangle 7'
        fill='currentColor'
        d='M5.208 42.356l-4.022-3.495L34.965-.015l4.022 3.495z'
      />
      <path
        data-name='Rectangle 9'
        fill='currentColor'
        d='M0 4.856l3.767-3.767 36.41 36.41-3.767 3.767z'
      />
    </svg>
  );
}

const StyledMenuClose = styled(MenuClose)`
  color: ${({ $color = "black", theme }) =>
    theme.colors[$color] ? theme.colors[$color] : $color};
`;

export default StyledMenuClose;
