import colors from "./colors";
import * as fonts from "./fonts";
import flexbox from "./flexbox";
import variables from "./variables";
import breakpoints from "./breakpoints";
import awesomegrid from "./awesome-styled-grid";

const theme = team => ({
  colors: colors[team],
  fonts,
  variables,
  breakpoints,
  flexbox,
  awesomegrid,
});

export default theme;
