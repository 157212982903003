/**
 * Layout component that uses the awesome-styled-grid Row
 * See: https://awesome-styled-grid.netlify.app/usage
 */
import styled from "styled-components";
import { Row as GridRow } from "react-awesome-styled-grid";
import { gridPropsNotToPass } from "~styles/mixins/forward-props";

const Row = styled(GridRow).withConfig(gridPropsNotToPass)``;

export default Row;
