import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const NavLink = ({
  name,
  display,
  url,
  onAnchorLinkClick,
  style,
  className,
  ...props
}) => {
  const classNames = `menu-item ${className}`.trim();

  return (
    <AnchorLink
      id={name}
      to={url}
      title={display}
      onAnchorLinkClick={onAnchorLinkClick}
      className={classNames}
      activeClassName='active'
      style={style}
      stripHash={false}
      {...props}
    >
      {display}
    </AnchorLink>
  );
};

export default NavLink;
