import React, { useState } from "react";
import styled, { css } from "styled-components";
import { theme } from "styled-tools";
import { rem } from "polished";
import { slide as Menu } from "react-burger-menu";
import ScrollLock from "react-scrolllock";
import { aboveSize } from "~styles/mixins/media-queries";
import NavLink from "./nav-link";
import CenteredCol from "./centered-col";
import Row from "./row";
import Col from "./col";
import BurgerMenu from "../icons/burger-menu";
import MenuClose from "../icons/menu-close";
import { trackEvent, trackClick } from "~src/utils/track";

const NavbarMobile = ({ menuItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openMenu = () => setIsOpen(true);
  const closeMenu = () => setIsOpen(false);

  // clicking burger menu icon
  const handleMenuOpenClick = () => {
    trackClick({ event_category: "navigation", event_label: "menu-open" });
    trackEvent("Nav Opened", {
      event_category: "navigation",
      event_label: "header",
    });
    openMenu();
  };

  // generally tracking close event
  const handleMenuClose = () => {
    trackEvent("Nav Closed", {
      event_category: "navigation",
      event_label: "header",
    });
    closeMenu();
  };

  // tracking menu close icon
  const handleCloseClick = () => {
    trackClick({ event_category: "navigation", event_label: "menu-close" });
    handleMenuClose();
  };

  // tracking nav item clicks
  const handleLinkClick = page => () => {
    trackClick("Nav Item Clicked", {
      event_category: "navigation",
      event_label: "Nav Item Clicked",
      value: page,
    });
    handleMenuClose();
  };

  // const handleStateChange = state => {
  //   // ignore the first event sent on load
  //   if (isOpen !== state.isOpen) {
  //     trackEvent(state.isOpen ? "Nav Opened" : "Nav Closed", {
  //       event_category: "navigation",
  //       event_label: "header",
  //     });
  //   }
  //   // set open state
  //   setIsOpen(state.isOpen);
  // };

  return (
    <StyledMenuRow justify='end' style={{ textAlign: "right" }}>
      <ScrollLock isActive={isOpen} />
      <Col>
        <Menu
          isOpen={isOpen}
          onOpen={handleMenuOpenClick}
          onClose={handleCloseClick}
          // onStateChange={handleStateChange}
          pageWrapId={"layout-main"}
          outerContainerId={"page-outer-wrapper"}
          customBurgerIcon={<BurgerMenu $color='kit' />}
          customCrossIcon={<MenuClose />}
          right
          width={"100%"}
          height={"100%"}
        >
          {menuItems && menuItems.length > 0 && (
            <StyledCenterCol>
              <ItemsRow justify='start' align='center'>
                {menuItems.map(item => (
                  <ItemCol
                    key={`nav-item-mobile-${item.name}`}
                    justify='center'
                    xs={4}
                    sm={8}
                    md={8}
                    lg={12}
                    xl={12}
                  >
                    <MenuLink
                      {...item}
                      onAnchorLinkClick={handleLinkClick(item.name)}
                    />
                  </ItemCol>
                ))}
              </ItemsRow>
            </StyledCenterCol>
          )}
        </Menu>
      </Col>
    </StyledMenuRow>
  );
};

const MenuLink = styled(NavLink)`
  color: ${theme("colors.black")};
  font-family: Knockout-HTF49-Liteweight;
  text-align: left;

  font-size: 24px;
  line-height: 47px;
  letter-spacing: 3.9px;

  ${aboveSize(
    "sm",
    css`
      font-size: 40px;
      line-height: 47px;
      letter-spacing: 6px;
    `,
  )}

  &:hover {
    text-decoration: none;
    color: ${theme("colors.white")};
  }
`;

const ItemsRow = styled(Row)`
  height: 100%;
  max-height: 456px;
  padding-top: 100px;
`;

const ItemCol = styled(Col)``;

const StyledCenterCol = styled(CenteredCol)`
  height: 100%;

  ${Row} {
    height: 100%;
  }
`;

const StyledMenuRow = styled(Row)`
  button {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .bm-burger-button {
    position: relative;
    width: 2rem;
    height: 2rem;
    display: inline-block;
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .bm-cross-button {
    position: absolute;
    width: 2rem !important;
    height: 2rem !important;
    right: 2rem !important;
    top: 2rem !important;
    color: ${theme("colors.black")};
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    .bm-cross {
      height: 2rem !important;
    }
    :hover .bm-cross {
      color: ${theme("colors.white")};
    }
  }

  .bm-menu-wrap {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: ${theme("colors.kit")};
    align-items: center;
    justify-content: center;
    display: inline-flex;

    .bm-menu {
      font-size: ${rem(36)};
      width: 100%;
      height: 100% !important;
      overflow: hidden;

      .bm-item-list {
        width: 100%;
        text-align: left;

        .bm-item {
          height: 100%;
          a {
            outline: none;
            color: ${theme("colors.black")};

            &.active {
              color: ${theme("colors.white")};
            }
            :hover {
              color: ${theme("colors.white")};
            }
          }
        }
      }
    }
  }
`;

export default NavbarMobile;
