/**
 * Layout component that uses the awesome-styled-grid Col
 * See: https://awesome-styled-grid.netlify.app/usage
 */
import styled from "styled-components";
import { Col as GridCol } from "react-awesome-styled-grid";
import { gridPropsNotToPass } from "~styles/mixins/forward-props";

const Col = styled(GridCol).withConfig(gridPropsNotToPass)``;

export default Col;
