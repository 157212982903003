import React from "react";
import Navigation from "./navigation";
import styled from "styled-components";
import { theme, ifProp } from "styled-tools";

const Header = ({ fixed = false, menuItems = [], rootPath = "", Logo }) => {
  return (
    <StyledHeader $fixed={fixed}>
      <Navigation menuItems={menuItems} rootPath={rootPath} Logo={Logo} />
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  color: ${theme("colors.white")};
  position: ${ifProp({ $fixed: true }, "fixed", "relative")};
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding: 1.5rem 0 1.5rem 0;
  background-color: ${theme("colors.black")};
`;

export default Header;
