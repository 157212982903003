import { createGlobalStyle } from "styled-components";
import resetercss from "reseter.css/src/styled-components/js/reseter.js";
import colors from "./colors";
import { fontBasics } from "./fonts";

// global stylesheet for the page
const GlobalStyles = createGlobalStyle`
  ${resetercss}

  html {
    height: -webkit-fill-available;
  }

  /*:target:before {
    content: "";
    display: block;
    height: 115px;
    margin: -115px 0 0;
  }*/

  body {
    background-color: ${colors.black};
    color: ${colors.white};
    margin: 0;
    padding: 0;
    font-size: 16px;
    ${fontBasics}
  }

  a, button, svg {
    outline: none;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    :hover, :focus {
      outline: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    
  }

  h1, h2, h3, h4, h5 {
    ${fontBasics}
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }
  /* Try to check if screen is landscape on mobile and rotate it to portrait */
  @media only screen and (min-width: 320px) and (max-width: 1023px) and (min-aspect-ratio: 13/9) and (orientation: landscape) {
    html {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }
`;

export default GlobalStyles;
