// For use with gtag.js
// see: https://developers.google.com/analytics/devguides/collection/gtagjs/events

import { reject, anyPass, isEmpty, isNil } from "rambdax";
const filterParams = reject(anyPass([isEmpty, isNil]));

// send ga tracking event if it exists on window
export const track = (eventType = "event", action = "click", params = {}) => {
  if (typeof window !== "undefined" && window?.gtag) {
    window.gtag(eventType, action, filterParams(params));
  } else {
    console.info("GA EVENT", {
      eventType,
      action,
      params: filterParams(params),
      fulllParams: params,
    });
  }
};

// for manually tracking an event
export const trackEvent = (
  action,
  params = {
    event_category: null,
    event_label: null,
    value: null,
  },
) => {
  track("event", action, params);
};

// for manually tracking a click
export const trackClick = (
  params = {
    event_category: null,
    event_label: null,
    value: null,
  },
) => {
  track("event", "click", params);
};

// for manually tracking a page
export const trackPage = (
  params = {
    page_title: null,
    page_location: null,
    page_path: null,
    send_to: null,
  },
) => {
  track("event", "page_view", params);
};
