const config = {
  mediaQuery: "only screen",
  columns: {
    xs: 4,
    sm: 8,
    md: 8,
    lg: 12,
    xl: 12,
  },
  // in rem
  gutterWidth: {
    xs: 1,
    sm: 1,
    md: 1.5,
    lg: 1.5,
    xl: 1.5,
  },
  // in rem
  paddingWidth: {
    xs: 2,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 2,
  },
  // in rem
  container: {
    xs: "full", // 'full' = max-width: 100%
    sm: "full", // 'full' = max-width: 100%
    md: "full", // 'full' = max-width: 100%
    lg: 90, // max-width: 1440px
    xl: 90, // max-width: 1440px
  },
  // in rem
  breakpoints: {
    xs: 1,
    sm: 48, // 768px
    md: 64, // 1024px
    lg: 90, // 1440px
    xl: 120, // 1920px
  },
};

export default config;
